#rating-div{
    background-color: #1E2E45
}

.rating-details{
    margin-top: 20px;
}

button.rating-star{
    padding-left: 3px;
    padding-right: 3px;
}

.modal-footer .disabled-demo{
    filter: grayscale(100%) brightness(50%);
}

