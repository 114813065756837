.banner {
  overflow: hidden;
  padding-top: 5rem;

  .owl-carousel {
    position: relative;

    .owl-stage-outer {
      overflow: visible;
    }

    .owl-stage {
      display: flex;

      .owl-item {
        flex: 1 0 auto;
      }

    }

    .owl-dots {
      position: absolute;
      bottom: 1rem;
      left: 8vw;

      .owl-dot {

        span {
          background: #869791 !important;
          border-radius: 0;
        }

        &.active,
        &:hover {

          span {
            background: #D6D6D6 !important;
          }

        }

      }

    }

  }

  .banner-slide {
    position: relative;
    height: 100%;
    padding: 1.375rem 8vw 4rem 8vw;
    background: linear-gradient(90deg, rgba(30,46,68,1) 0%, rgba(17,57,108,1) 62%, rgba(1,111,253,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .banner-slide .disabled-demo{
    filter: grayscale(100%) brightness(50%);
  }

  .content {
    width: 35vw;
    margin-bottom: 2rem;

    h2 {
      font-size: 24px;
    }

  }

  .btn {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.5rem;
  }

  .illu {
    position: absolute;
    height: calc(100% + 5rem);
    width: 30vw;
    top: -5rem;
    right: 8vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

  }

}

@include media-breakpoint-down(xl) {

  .banner {
    padding-top: 0;

    .illu {
      height: 100%;
      top: 0;
    }

  }

}

@include media-breakpoint-down(xl) {

  .banner {

    .illu {
      right: 0;
      width: 45vw;
    }

  }

}

@include media-breakpoint-down(md) {

  .banner {
    padding-top: 0;

    .owl-carousel {

      .owl-dots {
        left: 2rem;
      }

    }

    .banner-slide {
      padding: 2rem 2rem 4rem 2rem;
    }

    .content {
      width: 100%;
    }

    .illu {
      display: none;
    }

  }

}
