.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
  display: flex;
  gap: 1.25rem;
  align-items: center;
  justify-content: space-between;
  height: $nav-mobile-height;
  padding: 0 1.5rem;
  background-color: $black;

  .burger {

    span {
      position: relative;
      z-index: 1;
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      background: $white;
      border-radius: 3px;
      transition: transform .5s cubic-bezier(.77, .2, .05, 1), background .5s cubic-bezier(.77, .2, .05, 1), opacity .55s ease;
      transform-origin: 4px 0;

      &:first-child {
        transform-origin: 0 0;
      }

      &:last-child {
        margin-bottom: 0;
        transform-origin: 0 100%;
      }

    }

  }

  .main-nav {
    position: fixed;
    top: $nav-mobile-height;
    right: 0;
    bottom: 0;
    left: -30%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 2rem;
    justify-content: space-between;
    padding: 1.25rem;
    pointer-events: none;
    background-color: $black;
    opacity: 0;
    overflow: auto;
    transition: all ease .5s;

    > ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      > li > .nav-link {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: .5rem .5rem .5rem 1rem;
        border-left: .3125rem solid transparent;
        transition: all ease .3s;

        i.uil {
          font-size: 1.5rem;
        }

        svg path {
          stroke: $white;
        }

        &:hover,
        &.active {
          border-color: $primary;
          color: $primary;

          svg path {
            stroke: $primary;
          }

        }

        &.active {
          font-weight: 600;
        }

      }

      > li ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          margin-top: 1rem;
        }

        .nav-link {
          padding: .25rem 1.5rem;
        }

      }

      /** Je laisse ces 2 déclarations mais je ne comprend pas à quoi elles servent */
      .badge { top: 4px; left: 21px !important; }
      .notify { width: 18px; height: 20px; }

    }

  }

  .demo-flag {
    padding:7px;
  }

  &.opened {

    .burger {

      span:first-child {
        transform: rotate(45deg) translate(-5px, -3px);
      }

      span:nth-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(.2, .2);
      }

      span:last-child {
        transform: rotate(-45deg) translate(0, -2px);
      }

    }

    .main-nav {
      left: 0;
      pointer-events: auto;
      opacity: 1;
    }

  }

}

@include media-breakpoint-up(md) {

  .sidebar {

    .burger {
      display: none;
    }

    .main-nav {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      flex-grow: 1;
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 0;
      gap: 2rem;
      justify-content: space-between;
      pointer-events: auto;
      background-color: transparent;
      opacity: unset;
      overflow: visible;

      > ul {
        flex-direction: row;
        gap: .5rem;

        > li {
          position: relative;
        }

        > li > .nav-link {
          padding: .5rem;
          border-width: 0;

          > span:not(.position-relative) {
            display: none;
          }

        }

        > li > ul {
          position: absolute;
          top: 100%;
          left: 0;
          background-color: $black;
          width: 300px;
          padding-bottom: 1.5rem;
        }

      }

    }

  }

}

@include media-breakpoint-up(xl) {

  .sidebar {
    right: unset;
    bottom: 0;
    height: auto;
    width: $nav-width;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;

    .main-nav {
      flex-direction: column;

      > ul {
        flex-direction: column;
        width: 20rem;

        > li > .nav-link {
          padding: .5rem .5rem .5rem 1rem;
          border-width: 0.3125rem;
          gap: 1.25rem;

          span {
            display: inline-block !important;
          }

          &:hover {
            background-color: rgb(13 88 226 / 22%);
          }

        }

        > li > ul {
          position: relative;
          top: unset;
          left: unset;
          background-color: unset;
          width: unset;
          padding-bottom: unset;
        }

      }

    }

    &:hover {
      width: 20rem;
    }

  }

}
