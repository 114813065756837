.formation-card {

  .head {
    position: relative;
    aspect-ratio: 1 / 1.15;
    background-color: $black;
    cursor: pointer;

    .cover {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

    }

    .number {
      position: absolute;
      bottom: 0;
      left: 1rem;
      font-family: Guyhoquet, sans-serif;
      font-size: 65px;
      line-height: 1;
      transform: translateY(25%);
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      color: $primary;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.125rem;
      line-height: 1;
      background-color: $black;
    }

  }

  .body {
    position: relative;
    padding: .5rem 2.5rem .5rem 1rem;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .25rem;
    min-height: 6rem;
    transition: all ease .3s;
    cursor: pointer;

    &:after {
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.707.793a1 1 0 0 0-1.414 1.414L12.586 7.5H1a1 1 0 0 0 0 2h11.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7 .007-.006A.997.997 0 0 0 16 8.503v-.006a.996.996 0 0 0-.286-.698l-.008-.007m0 0-6.999-7Z" fill="%23006FFF"/></svg>');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      opacity: .25;
    }

    .card-title {
      font-size: 0.875rem;
      line-height: 1.3;
      color: $primary;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      text-transform: uppercase;
    }

    [class^="style-module_starRating"] {
      transform: scale(.8);
      transform-origin: bottom left;
      margin-top: -.5rem;
    }

    &:hover {
      background-color: $primary;

      &:after {
        background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.707.793a1 1 0 0 0-1.414 1.414L12.586 7.5H1a1 1 0 0 0 0 2h11.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7 .007-.006A.997.997 0 0 0 16 8.503v-.006a.996.996 0 0 0-.286-.698l-.008-.007m0 0-6.999-7Z" fill="%23ffffff"/></svg>');
      }

      .card-title {
        color: $white;
      }

    }

  }

}

.reverse-formation-card {
  min-height: 240px;
  width: auto;
  background-color: #1E2E45;
  padding: 1.875rem;

  .titleBack {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #fff;
  }

  .textBack {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: $white;
  }

}

@include media-breakpoint-down(lg) {

  .formation-card {

    .body {
      padding: .5rem 1.75rem .5rem .5625rem;
      min-height: 4.75rem;

      &:after {
        right: .5625rem;
        width: .6875rem;
        height: .6875rem;
      }

      .card-title {
        font-size: .625rem;
      }

      [class^="style-module_starRating"] {
        transform: scale(.6);
        margin-top: -.75rem;
      }

    }

  }

}

.react-card-flip.disabled-demo{
  filter: grayscale(100%) brightness(50%);
}
