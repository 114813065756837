.dropdown.dropright {
  position: absolute;
  top: 37px;
  right: 0;
  z-index: 999;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: rgb(13 88 226 / 22%);
}

.dropdown-toggle.without-caret {
  &::after{
    display: none;
  }
}

.dropdown-disabled {
    color: #808080;
    pointer-events: none;
    background-color: transparent;
}


.categories-navigator .disabled-demo{
  opacity: 0.5;
  cursor: default;
}
